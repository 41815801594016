
import React, { useEffect, useState } from "react";
import App from "./App";
import Preloder from './Preloader/Preloader';
import { createPortal } from "react-dom";

const This = ()=>{


    const [d, setD] = useState(false);

    useEffect(()=>{
        setD(true)
        setTimeout(() => {
            setD(false);
        }, 3500);
    }, []);

    const swap = createPortal(<> <App /> </>, document.querySelector('.os_webkit_moz_ms_fox'));

    return(<>

        { d ?
            <Preloder />
        
        : swap }
        
    </>)
}

export default This;